<template>
  <b-modal
    id="modal-asset-detail"
    ref="refModalAssetDetail"
    :title="t('Thông tin tài sản')"
    size="lg"
    scrollable
    :hide-footer="true"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
  >

    <div class="d-flex justify-content-center mb-1">
      <b-spinner
        v-if="isLoading"
        class="text-center"
        variant="secondary"
      />
    </div>

    <div v-if="itemLocal && !isLoading">
      <b-list-group class="">
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Mã QRCode') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Tên tài sản') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Thương hiệu') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.brand }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Màu sắc') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.color }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Model/Năm sản xuất') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.model }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Xuất xứ') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.madeIn }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Giá trị') }}:
            </b-col>
            <b-col class="">
              {{ Number(itemLocal.price).toLocaleString() }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Tình trạng') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.status }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Hạn bảo hành') }}:
            </b-col>
            <b-col class="">
              {{ parseDateToString(itemLocal.warranty ) }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.provider">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Nhà cung cấp') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.provider.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.apartment">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Tòa nhà') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.apartment.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.room">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Phòng') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.room.name }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item v-if="itemLocal.location">
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Vị trí') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.location }}
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col class="font-weight-bolder">
              {{ t('Ghi chú') }}:
            </b-col>
            <b-col class="">
              {{ itemLocal.note }}
            </b-col>
          </b-row>
        </b-list-group-item>

      </b-list-group>
      <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary pb-1">
        {{ t('Hình ảnh') }}
      </div>
      <media-view
        v-model="itemLocal.attachments"
        :label="null"
        :initial-value="itemLocal.attachments"
        :upload-imediately="true"
        :target-object="{id: itemLocal.id, type: 'asset'}"
        :preview-mode="!$can('update', 'asset')"
        @on-delete-uploaded-file="onDeleteAttachment"
      />

    </div>
    <!-- Body -->

  </b-modal>
</template>

<script>
import {
  BModal,
  BSpinner,
  BCol,
  BRow,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import { parseDateToString } from '@/auth/utils';
import MediaView from '@/views/components/media/MediaView.vue';
import useAssetDetailModal from './useAssetDetailModal';

export default {
  components: {
    BModal,
    BSpinner,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    MediaView,
  },
  props: {
    assetId: {
      type: Number,
      default: 0,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      onOpen,
      isLoading,
      t,
      onDeleteAttachment,
    } = useAssetDetailModal(props, emit, refFormObserver);

    return {
      refModalDeleteCashbook,
      itemLocal,
      resetItemLocal,
      resetModal,
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
      onOpen,
      isLoading,
      onDeleteAttachment,
      parseDateToString,
      t,
    };
  },
};
</script>

<style lang="scss">
  @import "@core/scss/vue/libs/vue-good-table.scss";
  .vgt-wrap {
    .vgt-table {
      thead {
        th {
          font-size: 1rem !important;
          font-weight: 500;
        }
      }
      td {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
  }
  </style>
